import { inject, Injectable } from '@angular/core';
import { OAuthService, TokenResponse } from 'angular-oauth2-oidc';
import { AuthConfigExtended } from '@logic-suite/shared/auth/auth.config';
import { OAuthSuccessEvent } from 'angular-oauth2-oidc/events';
import { defer, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OAuthWrapperService {
  private readonly oauthService = inject(OAuthService);

  configure(authConfig: AuthConfigExtended): void {
    this.oauthService.configure(authConfig);
  }

  loadDiscoveryDocument(): Promise<OAuthSuccessEvent> {
    return this.oauthService.loadDiscoveryDocument();
  }

  loadDiscoveryDocument$(): Observable<OAuthSuccessEvent> {
    return defer(() => this.oauthService.loadDiscoveryDocument());
  }

  tryLoginCodeFlow(): Promise<void> {
    return this.oauthService.tryLoginCodeFlow();
  }

  tryLoginCodeFlow$(): Observable<void> {
    return defer(() => this.oauthService.tryLoginCodeFlow());
  }

  hasValidAccessToken(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }

  initCodeFlow(returnTo?: string, additionalParams?: {}): void {
    this.oauthService.initCodeFlow(returnTo, additionalParams);
  }

  revokeTokenAndLogout(customParameters?: boolean): Promise<any> {
    return this.oauthService.revokeTokenAndLogout(customParameters);
  }

  revokeTokenAndLogout$(customParameters?: boolean): Observable<any> {
    return defer(() => this.oauthService.revokeTokenAndLogout(customParameters));
  }

  logOut(customParameters: boolean | object): void {
    this.oauthService.logOut(customParameters);
  }

  setupAutomaticSilentRefresh(): void {
    this.oauthService.setupAutomaticSilentRefresh();
  }

  refreshToken(): Promise<TokenResponse> {
    return this.oauthService.refreshToken();
  }

  refreshToken$(): Observable<TokenResponse> {
    return defer(() => this.oauthService.refreshToken());
  }

  getRefreshToken(): string | null {
    return this.oauthService.getRefreshToken();
  }

  get state(): string | undefined {
    return this.oauthService.state;
  }

  get events() {
    return this.oauthService.events;
  }
}
