import { computed, inject, Injectable, signal } from '@angular/core';
import { OAuthErrorEvent, OAuthEvent, OAuthInfoEvent, OAuthSuccessEvent } from 'angular-oauth2-oidc';
import { catchError, EMPTY, filter, Observable, share } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Logger } from '@logic-suite/shared/logger/logger.service';
import { tap } from 'rxjs/operators';
import { OAuthWrapperService } from '@logic-suite/shared/auth/o-auth-wrapper/o-auth-wrapper.service';

interface OAuthEventState {
  events: OAuthEvent[];
  lastInfoEvent: OAuthInfoEvent | null;
  lastSuccessEvent: OAuthSuccessEvent | null;
  lastErrorEvent: OAuthErrorEvent | null;
}

// Maximum number of events to store.
const MAX_EVENTS = 100;

/**
 * Wrapper for event stream provided by [OAuthService].
 *
 * Maintains internal state of latest events using signals,
 * ensuring event tracking even without active subscriptions.
 */
@Injectable({ providedIn: 'root' })
export class OAuthEventService {
  private readonly logger = inject(Logger);
  private readonly oAuthWrapperService = inject(OAuthWrapperService);

  // sources
  event$ = this.oAuthWrapperService.events.pipe(
    catchError((error) => {
      this.logger.error('Unexpected error in OAuth event stream', 'OAuthEventService', error);
      return EMPTY;
    }),
    share(), // Don't create multiple subscriptions.
  );

  infoEvent$: Observable<OAuthInfoEvent> = this.event$.pipe(
    filter((event): event is OAuthInfoEvent => event instanceof OAuthInfoEvent),
    tap((event) => this.logOauthInfoEvent(event)),
  );

  successEvent$: Observable<OAuthSuccessEvent> = this.event$.pipe(
    filter((event): event is OAuthSuccessEvent => event instanceof OAuthSuccessEvent),
    tap((event) => this.logOAuthSuccessEvent(event)),
  );

  errorEvent$: Observable<OAuthErrorEvent> = this.event$.pipe(
    filter((event): event is OAuthErrorEvent => event instanceof OAuthErrorEvent),
    tap((event) => this.logOAuthErrorEvent(event)),
  );

  // state
  private state = signal<OAuthEventState>({
    events: [],
    lastInfoEvent: null,
    lastSuccessEvent: null,
    lastErrorEvent: null,
  });

  // selectors

  events = computed(() => this.state().events);
  lastInfoEvent = computed(() => this.state().lastInfoEvent);
  lastSuccessEvent = computed(() => this.state().lastSuccessEvent);
  lastErrorEvent = computed(() => this.state().lastErrorEvent);

  constructor() {
    // reducers
    this.event$.pipe(takeUntilDestroyed()).subscribe((event) =>
      this.state.update((state) => ({
        ...state,
        events: [event, ...state.events.slice(0, MAX_EVENTS - 1)],
      })),
    );

    this.infoEvent$
      .pipe(takeUntilDestroyed())
      .subscribe((event) => this.state.update((state) => ({ ...state, lastInfoEvent: event })));

    this.successEvent$
      .pipe(takeUntilDestroyed())
      .subscribe((event) => this.state.update((state) => ({ ...state, lastSuccessEvent: event })));

    this.errorEvent$
      .pipe(takeUntilDestroyed())
      .subscribe((event) => this.state.update((state) => ({ ...state, lastErrorEvent: event })));
  }

  private logOauthInfoEvent(event: OAuthInfoEvent): void {
    this.logger.debug('OAuth info event received.', 'OAuthEventService', event);
  }

  private logOAuthSuccessEvent(event: OAuthSuccessEvent): void {
    this.logger.debug('OAuth success event received.', 'OAuthEventService', event);
  }

  private logOAuthErrorEvent(event: OAuthErrorEvent): void {
    this.logger.warn('OAuth error event received.', 'OAuthEventService', event);
  }
}
