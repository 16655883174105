import { Component } from '@angular/core';
import { resetClient } from '@logic-suite/shared/utils';

@Component({
  selector: 'lib-reset',
  standalone: true,
  template: `
    <h1 translate>Resetting client</h1>
    @if (error == null) {
      <h4 translate>We are resetting your client.</h4>
      <p translate>
        This will not destroy any data you have entered into the system, but you will have to log in again.
      </p>
    } @else {
      <h4 translate>Reset failed:</h4>
      <p translate>{{ error }}</p>
    }
  `,
  styles: [
    `
      :host {
        display: grid;
        position: fixed;
        background: var(--background);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 !important;
        z-index: 1000;
        place-content: center;
        place-items: center;
      }
      h1 {
        margin-bottom: 1rem;
      }
      h4 {
        margin-bottom: 2rem;
      }
      p {
        text-align: center;
      }
    `,
  ],
})
export class ResetComponent {
  error: string | null = null;

  constructor() {
    setTimeout(() => this.reset(), 2000);
  }

  async reset() {
    const res = await resetClient();
    this.error = res !== true ? res : null;

    // Reload app from root
    if (this.error == null) {
      location.href = '/';
    }
  }
}
