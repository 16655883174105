import { InjectionToken } from '@angular/core';

export interface AUTH_ENV {
  clientId: string;
  issuer: string;
  tenantId: string;
  redirectUri: string;
  custom?: { [key: string]: string };
}

/**
 * Environmental configuration properties for auth0
 */
export const AUTH_TOKEN = new InjectionToken<AUTH_ENV>('auth.token');
