import { Injectable, inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, firstValueFrom } from 'rxjs';
import { AuthService } from '.';
import { getEnv } from '../utils';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  auth = inject(AuthService);
  router = inject(Router);

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.handleAuthState();
  }

  canLoad(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.handleAuthState();
  }

  async handleAuthState() {
    const params = new URLSearchParams(window.location.search);
    let returnTo = (params.has('returnTo') ? params.get('returnTo') : location.href) || undefined;
    if (['login', 'callback', 'logout'].some(s => returnTo?.includes(s))) returnTo = undefined;
    if ('Cypress' in window) return true;
    const isLoggedIn = await firstValueFrom(this.auth.isLoggedIn$);
    if (isLoggedIn) {
      return true;
    } else if (getEnv('customLogin') !== true) {
      this.auth.login({ returnTo });
      return false;
    } else {
      return this.router.createUrlTree(['/login'], { queryParams: { returnTo } });
    }
  }
}
