<form (submit)="login()">
  <header>
    <img src="assets/logo.svg" />
    <h3>{{ applicationName }}</h3>
  </header>
  @if (!lastUser()) {
    <div class="content">
      <p>{{ 'Please fill in the email address you wish to log on with' | translate }}</p>
      <mat-form-field appearance="fill" floatLabel="always">
        <mat-label translate>Email</mat-label>
        <input matInput [formControl]="email" type="email" autocomplete="username" />
        <mat-icon matPrefix>email</mat-icon>
      </mat-form-field>
    </div>
    <footer>
      <button mat-raised-button type="submit" color="primary" [disabled]="!email.value" (click)="login()">
        {{ 'Login' | translate }}
      </button>
    </footer>
  } @else {
    <div class="content">
      <p>{{ 'Last time you logged in with' | translate }}</p>
      <button mat-raised-button color="primary" type="button" (click)="login()">
        {{ lastUser() }}
      </button>
      <button mat-button (click)="reset()">{{ 'Not your account' | translate }}?</button>
    </div>
  }
</form>
