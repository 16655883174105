import { InjectionToken } from '@angular/core';

export interface STATUS_REDIRECT {
  statusCode: number;
  redirectTo: string[];
}

/**
 * Injection token for configuring redirects based on http response status codes.
 * I.E. If a request responds with 401, go to /login (this is built in by the way).
 *
 * #USAGE
 * @NgModule({
 *   providers: [
 *     { provide: HTTP_STATUS_REDIRECT, useValue: [{ statusCode: 403, redirectTo: ['/customer'] }] }
 *   ]
 * })
 */
export const HTTP_STATUS_REDIRECT = new InjectionToken<STATUS_REDIRECT[]>('http.status.redirect');
