import { AbstractControl, ValidatorFn } from '@angular/forms';
import { endOfDay, isAfter, isDate } from 'date-fns';

export class CustomValidator {
  static email(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (Array.isArray(control.value)) {
        return control.value.some(v => !isValidEmail(v)) ? { email: true } : null;
      } else {
        return !isValidEmail(control.value) ? { email: true } : null;
      }
    };
  }

  static phone(): ValidatorFn {
    const checkLength = (val: string) => {
      if (!!val && val.length < 8) {
        return { minLength: { requiredLength: 8, actualLength: val.length } };
      }
      if (!!val && val.length > 12) {
        return { maxLength: { maxLength: 12, actualLength: val.length } };
      }
      return null;
    };

    return (control: AbstractControl): { [key: string]: any } | null => {
      if (Array.isArray(control.value)) {
        const errors = control.value.map(v => checkLength(v)).filter(v => !!v);
        return control.value.some(v => !isValidPhone(v)) ? { email: true } : errors.length ? errors : null;
      } else {
        return !isValidPhone(control.value) ? { email: true } : checkLength(control.value);
      }
    };
  }

  static date(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return control.value != null && !isDate(control.value) ? { date: true } : null;
    };
  }

  static dateBeforeToday(): ValidatorFn {
    return (ctrl: AbstractControl) => {
      if (isAfter(ctrl.value, endOfDay(new Date()))) {
        return { beforeToday: 'Date cannot be in future' };
      }
      return null;
    };
  }
}

export function isValidPhone(val: string): boolean {
  // eslint-disable-next-line no-useless-escape
  const PHONE_REGEXP = /^[0-9\+]{8,12}$/i;
  val = val?.split(' ').join('') ?? val; // Validate without spaces
  // Returns false if the non-null value fails the regexp test
  return !val || PHONE_REGEXP.test(val);
}

export function isValidEmail(val: string): boolean {
  // eslint-disable-next-line no-useless-escape
  const EMAIL_REGEXP = /^(?:[^\r\n\t\f\v \.@¤]\.{0,1})+@(?:[^\r\n\t\f\v \.@¤]\.{0,1})+\.[^\r\n\t\f\v \.@¤]{2,}$/i;

  // Returns false if the non-null value fails the regexp test
  return !val || EMAIL_REGEXP.test(val);
}
