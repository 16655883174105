import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';

import { getLocalStorage } from '../utils/getLocalStorage';
import { AuthInterceptor } from './auth.interceptor';
import { AuthService } from './auth.service';
import { CallbackComponent } from './callback.component';
import { InfoComponent } from './info.component';
import { LoginComponent } from './login.component';
import { LogoutComponent } from './logout.component';
import { ResetComponent } from './reset.component';

// We need a factory, since localStorage is not available during AOT build time.
export function storageFactory(): OAuthStorage {
  return getLocalStorage();
}

@NgModule({
  declarations: [LogoutComponent, CallbackComponent, LoginComponent],
  imports: [
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    TranslateModule,
    OAuthModule.forRoot(),
    RouterModule.forChild([
      // Login route, will redirect to identity provider
      { path: 'login', component: LoginComponent, title: `Login | ${document.title}` },
      // Logout just invalidates current token and removes user from localStorage. It will not log out from identity provider
      { path: 'logout', component: LogoutComponent, title: `Logout | ${document.title}` },
      // Callback after keycloak has done its thing
      { path: 'callback', component: CallbackComponent },
      // Route to show info about the app, version, browser, etc
      { path: 'info', component: InfoComponent, title: `Info | ${document.title}` },
      // Route to reset all client storage and service-worker
      { path: 'reset', component: ResetComponent, title: `Reset | ${document.title}` },
    ]),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: OAuthStorage, useFactory: storageFactory },
    {
      provide: APP_INITIALIZER,
      useFactory: (auth: AuthService) => () => auth.initAuth(),
      deps: [AuthService],
      multi: true,
    },
  ],
  exports: [LoginComponent],
})
export class AuthModule {}
