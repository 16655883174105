import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { TokenResponse } from 'angular-oauth2-oidc';
import { Observable, from, of, retry, switchMap, throwError } from 'rxjs';
import { AuthService } from '.';
import { HTTP_STATUS_REDIRECT, STATUS_REDIRECT } from './status-redirect.token';

/**
 * Custom implementation of the `DefaultOAuthInterceptor` from angular-oauth2-oidc
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private router: Router,
    @Optional() @Inject(HTTP_STATUS_REDIRECT) private statusRedirect?: STATUS_REDIRECT[],
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url?.toLowerCase() ?? '';

    const skip = req.headers.get('NoAuth') === 'true';
    if (req.headers.has('NoAuth')) {
      const newHeaders = req.headers.delete('NoAuth');
      req = req.clone({ headers: newHeaders });
    }
    if (skip) return next.handle(req);
    if (!url.startsWith('/api') || url.endsWith('/api/env.js')) return next.handle(req);

    return this.auth.getTokenSilently$().pipe(
      switchMap(token => {
        req = req.clone({
          headers: req.headers
            .set('X-Client-TimezoneOffset', `${-new Date().getTimezoneOffset() / 60}`)
            .set('X-Client-Timezone', `${Intl.DateTimeFormat().resolvedOptions().timeZone}`)
            .set('Authorization', `Bearer ${token}`),
        });
        return next.handle(req);
      }),
      retry({
        count: 1, // Only retry once
        delay: (err: any) => {
          if (err.status === 401) {
            // Try to login the last user or redirect to the login page if failed
            return from(this.auth.tryAutoLoginLastUser()).pipe(
              switchMap((token: TokenResponse) => {
                if (!token) {
                  this.logout();
                  return throwError(() => err);
                }
                return of(token.access_token);
              }),
            );
          }

          if (this.statusRedirect?.length) {
            const match = this.statusRedirect.find(m => m.statusCode === err.status);
            if (match) this.router.navigate(match.redirectTo);
          }
          return throwError(() => err);
        },
      }),
    );
  }

  /**
   * Logout and redirect to the current page. This will bring up the login dialog
   * before the navigation is completed.
   */
  logout() {
    const url = location.pathname + location.search;
    return this.auth.logout().then(() =>
      this.router.navigateByUrl(url).then(isNavigated => {
        if (!isNavigated) {
          location.href = url;
        }
      }),
    );
  }
}
