export enum AuthState {
  /** Initial state before app initialization */
  NOT_ACTIVE,

  /** App is starting up */
  INITIALIZING,

  /** Discovery document has been loaded */
  INITIALIZED,

  /** Validating or refreshing existing token */
  PROCESSING,

  /** Token processed (refreshed, missing, or expired) */
  PROCESSED,

  /** Authentication required */
  NEEDS_LOGIN,

  /** User successfully authenticated */
  AUTHENTICATED,
}
