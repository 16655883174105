import { AuthConfig } from 'angular-oauth2-oidc';
import { AUTH_ENV } from './auth.token';

export interface AuthConfigExtended extends AuthConfig {
  responseMode?: string;
}

export function createAuthConfig(authToken: AUTH_ENV, isDev: boolean): AuthConfigExtended {
  return {
    issuer: `${authToken.issuer}${authToken.tenantId || ''}`,
    redirectUri: window.location.origin + authToken.redirectUri,
    clientId: authToken.clientId,
    responseType: 'code',
    scope: 'openid profile email offline_access',
    showDebugInformation: isDev,
    ...(authToken.custom ? { customQueryParams: authToken.custom } : {}),

    // Allows time for token processing.
    waitForTokenInMsec: 500,

    responseMode: 'query',

    // Enables background token refresh.
    useSilentRefresh: true,

    // Prevents login loop due to session change events.
    sessionChecksEnabled: false,

    silentRefreshRedirectUri: window.location.origin + '/assets/silent-refresh.html',

    // Improves compatibility with some Identity Providers.
    strictDiscoveryDocumentValidation: false,

    // Ensures compatibility with Azure AD.
    skipIssuerCheck: true,
  };
}
