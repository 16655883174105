import { CommonModule } from '@angular/common';
import { Component, OnInit, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { AppService } from '../app.service';
import { Browser, getEnv } from '../utils';

type Cache = {
  key: string;
  shown: boolean;
  details: CacheDetails[];
};
type CacheDetails = {
  url: string;
  date?: Date;
};

@Component({
  selector: 'lib-info',
  templateUrl: './info.component.html',
  styleUrl: './info.component.scss',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
})
export class InfoComponent implements OnInit {
  env = getEnv('env');
  app = inject(AppService);
  appVersion?: string;
  browserName?: string;
  browserVersion?: string;
  isMobile = false;
  uaString = signal(navigator.userAgent);
  cache = signal<Cache[]>([]);
  updateDate = signal<Date | undefined>(undefined);

  async ngOnInit() {
    this.appVersion = await this.app.getVersion();
    const b = Browser.getBrowser();
    this.browserName = b.browser;
    this.browserVersion = b.version;
    this.isMobile = b.isMobile;
    this.app.getApplicationName();

    // Get cache keys
    this.readCache();
  }

  private async readCache() {
    const cacheDetails = [] as Cache[];
    const cacheNames = await self.caches.keys();
    for (const name of cacheNames) {
      const currentCache = { key: name, shown: false, details: [] } as Cache;
      cacheDetails.push(currentCache);

      // Get the details
      const cache = await caches.open(name);
      const requests = await cache.keys();
      for (const request of requests) {
        const response = await cache.match(request);
        const date = response?.headers.get('date');
        currentCache.details.push({
          url: request.url,
          date: date ? new Date(date) : undefined,
        });

        // The last update date is equal to the date of the first response in the precache cache
        // Must handle both Workbox SW and Angular SW
        if ((name.includes('precache') || name.includes('app:cache')) && date != null) {
          const d = new Date(date);
          if (this.updateDate() == null || d.getTime() > (this.updateDate()?.getTime() ?? -1)) {
            this.updateDate.set(d);
          }
        }
      }
    }
    this.cache.set(cacheDetails);
  }

  reload() {
    location.reload();
  }

  async clearCache(cacheName: string) {
    await caches.delete(cacheName);
    this.readCache();
  }

  async hardReset() {
    if (confirm('This performs a hard reset of the app. Are you sure?')) {
      location.href = '/reset';
    }
  }
}
